import type { ReactElement, ReactNode } from "react";

import sortBy from "lodash/sortBy";
import Link from "next/link";
import { isValidElement, useMemo, useRef, useState } from "react";

import IconButton from "@/components/Button/IconButton";
import Pagination from "@/components/Pagination";
import SvgIcon from "@/components/SvgIcon";

import useOverflow from "@/js/hooks/useOverflow";
import { classnames } from "@/js/utils/cambio";
import { getEmptyCell } from "@/js/utils/partials";

import useCopyCells from "./useCopyCells";

type TableContent = string | number | ReactElement;
type SortDirections = "asc" | "desc";

interface Sort {
  index: number;
  direction: SortDirections;
}

interface TableHeader {
  className?: string;
  content: ReactNode;
  numeric?: boolean;
  sort?: boolean;
}

interface TableCell {
  /** this can be anything from the raw cell value to a string to a react element to null */
  content?: TableContent;
  /** this should be the raw cell value, which will be used for some default sorting */
  compare?: string | number;
}

interface TableRow {
  // this is used to index the object for toggling nested rows
  key?: string;
  row: (TableCell | TableContent)[];
  /**
   * When present, a row has an arrow button that will toggle visibility of these rows. These child
   * rows are always nested underneath the parent and do not obey sort order or pagination. If
   * added, ensure that you also pass a `key` property for the row so that the Table component can
   * properly keep track of which rows have been toggled open.
   */
  childRows?: ((TableCell | TableContent)[] | TableRow)[];
  onClick?: () => void;
  className?: string;
  href?: string;
}

interface TableProps {
  copyable?: boolean;
  // we'll use this to add CSS grid to the table, which adds a lot of flexibility but requires set
  // column widths
  grid?: boolean;
  head: (string | ReactElement | TableHeader)[];
  body: ((TableContent | TableCell)[] | TableRow)[];
  initialSortIndex?: number;
  initialSortDirection?: "asc" | "desc";
  itemsPerPage?: number;
  /** a final table row to append to all pages (not itself paginated) */
  totals?: (TableContent | TableCell)[];
  // the following props are for externally-controlled pagination (ie when we use API pagination) */
  currentPage?: number;
  onChangePage?: (page: number) => void;
  totalResults?: number;
}

/**
 * Table component that handles sorting, client-side pagination, and even allows for copying cells.
 *
 * Later, we can augment this with things like:
 *
 * * "gridify"-ing for more control over column sizes
 * * API pagination, which this component currently supports by passing in `onChangePage` and
 *   `currentPage` props, but for which we'll also need to add an `onChangeSort` to override
 *   internal sorting.
 *
 * Other features this component supports:
 *   * Nested rows by passing a `childRows` property on a row
 *   * A "totals" row that will persist at the bottom even when paginating
 *   * numeric columns are right-aligned, everything else is left-aligned
 */
export default function Table({
  copyable = true,
  head,
  grid,
  body,
  initialSortIndex = 0,
  initialSortDirection = "asc",
  itemsPerPage = Infinity,
  ...props
}: TableProps) {
  /** This is to be used when pagination is client-side only */
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<Sort>({
    index: initialSortIndex,
    direction: initialSortDirection,
  });
  const [toggledRows, setToggledRows] = useState<Record<string, boolean>>({});
  const tableRef = useRef<HTMLTableElement>();
  const tableContainerRef = useRef<HTMLDivElement>();

  const onChangeSort = (index: number) =>
    setSort({
      index,
      direction: sort.index === index && sort.direction === "asc" ? "desc" : "asc",
    });

  const { overflowClassNames, onScroll } = useOverflow(tableContainerRef);

  const sortData = (data: ((TableContent | TableCell)[] | TableRow)[]) => {
    const sortedData = sortBy(
      data,
      (row) =>
        ((row as TableRow).row?.[sort.index] as TableCell)?.compare ??
        ((row as TableRow).row?.[sort.index] as TableCell)?.content ??
        ((row as TableRow).row?.[sort.index] as TableContent) ??
        (row as TableCell[])[sort.index]?.compare ??
        (row as TableCell[])[sort.index]?.content ??
        (row as TableContent[])[sort.index],
    );

    if (sort.direction === "desc") {
      sortedData.reverse();
    }

    return sortedData;
  };

  body = useMemo(() => {
    if (typeof head[sort.index] === "string" || !(head[sort.index] as TableHeader)?.sort) {
      return body;
    }

    return sortData(body);
  }, [body, sort.index, sort.direction]);

  // items we display, subject to client-side pagination
  const currentItems =
    itemsPerPage < body.length ? body.slice(page * itemsPerPage, (page + 1) * itemsPerPage) : body;
  // ghost rows get added when paginating if there aren't enough items to fill up the table (on the
  // last page), which keeps the table from changing height and the pagination from moving.
  const numberOfGhostRows =
    currentItems.length < itemsPerPage && page ? itemsPerPage - currentItems.length : 0;

  const { onMouseDown, onMouseEnter, isElementSelected, isFirstSelectedCell, isCopying } =
    useCopyCells(tableRef, currentItems.length);

  const renderTableCell = (
    cell: TableCell | TableContent,
    [i, j]: [number, number],
    row?: TableProps["body"][number],
  ) => (
    <td
      key={i + j}
      className={classnames({
        numeric: (head[j] as TableHeader)?.numeric,
        "first-selected": isFirstSelectedCell(i, j),
        selected: isElementSelected(i, j),
      })}
      onClick={!copyable && "onClick" in row ? row.onClick : null}
      onMouseDown={copyable ? () => onMouseDown(i, j) : null}
      onMouseEnter={copyable ? () => onMouseEnter(i, j) : null}
    >
      <span
        onMouseDown={(evt) => {
          // don't allow button or anchor clicks to reach the td element
          if (copyable && hasClickableAncestor(evt.target as Node, tableRef.current)) {
            evt.stopPropagation();
          }
        }}
      >
        {!j && row && "childRows" in row ?
          <IconButton
            icon={toggledRows[row.key] ? "chevron-down" : "chevron-right"}
            onClick={() => setToggledRows((rows) => ({ ...rows, [row.key]: !rows[row.key] }))}
          />
        : null}
        {(typeof cell === "string" || typeof cell === "number" || isValidElement(cell) ? cell
        : cell && "content" in cell ? cell.content
        : null) ?? getEmptyCell()}
      </span>
      <SvgIcon name="copy" />
      {row && "href" in row ?
        <Link href={row.href}>&nbsp;</Link>
      : null}
    </td>
  );

  return (
    <>
      <div
        className={classnames("Table", { grid, copyable, copying: isCopying }, overflowClassNames)}
      >
        <div ref={tableContainerRef} onScroll={onScroll}>
          <table ref={tableRef}>
            <thead>
              <tr>
                {head.map((col, i) =>
                  typeof col !== "string" && "content" in col ?
                    <th
                      key={i}
                      className={classnames(
                        { numeric: col.numeric, sortable: col.sort },
                        col.className,
                      )}
                      onMouseDown={copyable ? () => onMouseDown(-1, i) : null}
                      onMouseEnter={copyable ? () => onMouseEnter(-1, i) : null}
                    >
                      <span
                        onClick={col.sort ? () => onChangeSort(i) : null}
                        onMouseDown={copyable ? (evt) => evt.stopPropagation() : null}
                      >
                        {col.content}
                        {col.sort ?
                          <span
                            className={classnames("sort-icons", {
                              [sort.direction]: sort.index === i,
                            })}
                          >
                            <SvgIcon name="caret-up" />
                            <SvgIcon name="caret-down" />
                          </span>
                        : null}
                      </span>
                    </th>
                  : <th key={i}>{col}</th>,
                )}
              </tr>
            </thead>
            <tbody>
              {currentItems.flatMap((row, i) => [
                // we should not use i or j alone as keys because they are not stable across renders
                //   since the row looks like this:
                //   {"key":"cmp_d479FTmAU8zzhwWUzHRaWN","href":"/o_mXHykuKC2Xc6PRj9EPcfGk/properties/cmp_d479FTmAU8zzhwWUzHRaWN/overview","row":["Bay Area Business Park - Phase I","9311 Bay Area Blvd.","Pasadena","TX","77507","USA",{"content":"--","compare":0},{"content":"Distribution Center","compare":"Distribution Center"}],"childRows":[{"key":"s_on74o7c5duEEaA6hMKgGYw","href":"/o_mXHykuKC2Xc6PRj9EPcfGk/properties/s_on74o7c5duEEaA6hMKgGYw/overview","row":["Bay Area Business Park- Bldg 1","9311 Bay Area Blvd","Pasadena","TX","77507","USA",{"content":"219,000","compare":219000},{"content":"Non-Refrigerated Warehouse","compare":"Non-Refrigerated Warehouse"}]},{"key":"s_bKqRWRJ5qnZPYNSLdErywD","href":"/o_mXHykuKC2Xc6PRj9EPcfGk/properties/s_bKqRWRJ5qnZPYNSLdErywD/overview","row":["Bay Area Business Park- Bldg 2","9401 Bay Area Blvd","Pasadena","TX","77507","USA",{"content":"480,480","compare":480480},{"content":"Non-Refrigerated Warehouse","compare":"Non-Refrigerated Warehouse"}]},{"key":"s_PR7HXQ5EANFTjV8uwyCxhM","href":"/o_mXHykuKC2Xc6PRj9EPcfGk/properties/s_PR7HXQ5EANFTjV8uwyCxhM/overview","row":["Bay Area Business Park- Bldg 3","9501 Bay Area Blvd","Pasadena","TX","77507","USA",{"content":"480,480","compare":480480},{"content":"Non-Refrigerated Warehouse","compare":"Non-Refrigerated Warehouse"}]},{"key":"s_CSF5o97MWtd5Z33AEPHyUC","href":"/o_mXHykuKC2Xc6PRj9EPcfGk/properties/s_CSF5o97MWtd5Z33AEPHyUC/overview","row":["Bay Area Business Park- Bldg 4","9331 Bay Area Blvd","Pasadena","TX","77507","USA",{"content":"110,000","compare":110000},{"content":"Non-Refrigerated Warehouse","compare":"Non-Refrigerated Warehouse"}]},{"key":"s_E978kRSar2X2UkByBrTkxG","href":"/o_mXHykuKC2Xc6PRj9EPcfGk/properties/s_E978kRSar2X2UkByBrTkxG/overview","row":["Bay Area Business Park- Bldg 5","9431 Bay Area Blvd","Pasadena","TX","77507","USA",{"content":"353,600","compare":353600},{"content":"Non-Refrigerated Warehouse","compare":"Non-Refrigerated Warehouse"}]}]}
                //   so we should use `row.key` as the key, in case there isn't a key (not sure if this case exists), i'll still use i as a fallback.
                // @ts-ignore
                <tr key={`${row.key}-${i}`} className={"row" in row ? row.className : null}>
                  {("row" in row ? row.row : row).map((cell, j) =>
                    renderTableCell(cell, [i, j], row),
                  )}
                </tr>,
                // TODO: right now we only have a use-case for childRows on tables that don't copy.
                // we will need to update this if we ever need to copy tables with nested rows.
                ...("childRows" in row ?
                  (toggledRows[row.key] ? sortData(row.childRows) : []).map((childRow, k) => (
                    <tr key={`${i}${k}`} className="child-row">
                      {("row" in childRow ? childRow.row : childRow).map((cell, j) =>
                        renderTableCell(cell, [i + k + 1, j], childRow),
                      )}
                    </tr>
                  ))
                : []),
              ])}
              {props.totals ?
                <tr className="totals">
                  {props.totals.map((cell, j) => renderTableCell(cell, [body.length, j]))}
                </tr>
              : null}
              {/**
               * This trick will keep our pagination from moving up when there are fewer
               * rows on the last page of the table.
               */}
              {Array.from({ length: numberOfGhostRows }, () => (
                <tr className="ghost" />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        currentPage={props.currentPage || page}
        onChangePage={props.onChangePage || setPage}
        itemsPerPage={itemsPerPage}
        total={props.totalResults || body.length}
      />
    </>
  );
}

/**
 * This helper is important for our drag-to-select copy functionality, because anything clickable
 * within a cell we want to prevent from copying. So if any child is or is a descendent of a button
 * or anchor tag, return true.
 */
function hasClickableAncestor(node: Node, stopAt: HTMLElement): boolean {
  if (node === stopAt) {
    return false;
  } else if (!node || ["BUTTON", "A"].includes(node.nodeName)) {
    return true;
  }

  return hasClickableAncestor(node.parentElement, stopAt);
}

/**
 * Use this for consistent styling when a column header has a second line in a caption styling.
 */
export function ColumnHeaderWithCaption({
  title,
  caption = "\xa0",
}: {
  title: string;
  caption: string;
}) {
  return (
    <span className="ColumnHeaderWithCaption">
      {title}
      <span className="caption">{caption}</span>
    </span>
  );
}

export { default as EditableCell } from "./EditableCell";
