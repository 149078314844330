import type { RefObject } from "react";

import { useEffect, useRef, useState } from "react";

import useNotificationContext from "@/js/hooks/useNotificationContext";
import { track } from "@/js/services/mixpanel";

type TableCellCoords = [number, number];
type CopySquare = [[number, number], [number, number]];

/**
 * This hook handles everything we need to have a table cell "drag-to-select" copy experience. We
 * keep track of the first cell that gets moused down because that has a slightly different UI.
 * After that, every cell we hover over gets replaced as the second element of the "copy square"
 * tuple. When the user mouses up (which will fire a click event), we'll map our square indices to
 * table cells and save the text to the clipboard.
 */
export default function useCopyCells(tableRef: RefObject<HTMLTableElement>, numberOfRows: number) {
  /** signifies that we are in process of selecting our copy cells */
  const [isCopying, setIsCopying] = useState(false);
  /**
   * The initial cell always occupies the first entry of the copy square tuple. This will still
   * exist after we finish copying (isCopying will be false) because we show the selected state in
   * the UI until the user clicks outside the table.
   */
  const [initialCopyCell, setInitialCopyCell] = useState<TableCellCoords>(null);
  /** should always be two elements */
  const [copySquare, setCopySquare] = useState<CopySquare>(null);
  const sortedCopySquare = useRef<CopySquare>(null);
  const { notify } = useNotificationContext();

  // querying whether cells are in our square is much, much easier when it is sorted, but it's also
  // much easier to blindly append a new mouse-entered cell to our square than to figure out whether
  // it should be added before or after the initialCopyCell. so sort here!
  // note this is a ref so that what we pass to the copy handler is always current without needing
  // to generate new events everything the copy square changes.
  sortedCopySquare.current =
    copySquare ?
      [
        [Math.min(...copySquare.map(([x]) => x)), Math.min(...copySquare.map(([x, y]) => y))],
        [Math.max(...copySquare.map(([x]) => x)), Math.max(...copySquare.map(([x, y]) => y))],
      ]
    : null;

  /**
   * The click event here could be two events:
   *
   * 1. A normal click anywhere in the document
   * 2. A mouse up after mousing down to drag-select cells to copy
   *
   * In the former case, we check if they're clicking outside the table, and if so, reset our
   * copy state. In the latter, we end the copy session and send our current square to get content
   * copied to the clipboard!
   */
  useEffect(() => {
    if (initialCopyCell) {
      const onClickUp = async (evt: MouseEvent) => {
        if (!isCopying && !tableRef.current.contains(evt.target as Node)) {
          // if we're not isCopying and we click outside the table, reset
          setInitialCopyCell(null);
          setCopySquare(null);
        } else if (isCopying) {
          const square = sortedCopySquare.current;

          // otherwise copy && notify!
          setIsCopying(false);
          await onAutoCopyTableElements(square, tableRef.current);
          notify("Copied");

          track("Table - Elements Copied", {
            "Number of Cells":
              (square[1][0] - square[0][0] + 1) * (square[1][1] - square[0][1] + 1),
            "Single Column": square[0][1] === square[1][1] && square[0][0] !== square[1][0],
          });
        }
      };

      document.addEventListener("click", onClickUp);

      return () => document.removeEventListener("click", onClickUp);
    }
  }, [initialCopyCell, isCopying]);

  return {
    isCopying,
    isFirstSelectedCell(i: number, j: number) {
      return initialCopyCell?.[0] === i && initialCopyCell?.[1] === j;
    },
    isElementSelected(i: number, j: number) {
      return (
        sortedCopySquare.current &&
        // make sure we have two distinct cells here because we don't show single
        // cell squares as "selected" with the colored background
        !(
          sortedCopySquare.current[0][0] === sortedCopySquare.current[1][0] &&
          sortedCopySquare.current[0][1] === sortedCopySquare.current[1][1]
        ) &&
        isCellWithinSquare([i, j], sortedCopySquare.current)
      );
    },

    onMouseDown(i: number, j: number) {
      if (!isCopying) {
        // trigger copy session
        setIsCopying(true);
        setInitialCopyCell([Math.max(i, 0), j]);
        setCopySquare(
          i === -1 ?
            // if it's a column (denoted by -1 row index), select whole column
            [
              [0, j],
              [numberOfRows - 1, j],
            ]
            // else set it to the initial cell at both indices
          : [
              [i, j],
              [i, j],
            ],
        );
      }
    },
    onMouseEnter(i: number, j: number) {
      if (isCopying) {
        setCopySquare(
          copySquare.slice(0, 1).concat([[i === -1 ? numberOfRows - 1 : i, j]]) as CopySquare,
        );
      }
    },
  };
}

/**
 * Makes a 2-D array of a table's cells and filters by those that are within our copy square. After
 * that, we just join all columns with a tab character, and all rows with a newline. Then copy the
 * resulting string to the clipboard.
 */
async function onAutoCopyTableElements(square: CopySquare, tableElement: HTMLTableElement) {
  const tableCellContent = [...tableElement.querySelectorAll("tbody tr")]
    .reduce((rowText, row, i) => {
      const text = [...row.querySelectorAll("td")]
        .reduce((colText, cell, j) => {
          if (isCellWithinSquare([i, j], square)) {
            colText = colText.concat(cell.textContent);
          }

          return colText;
        }, [])
        .join("\t");

      return rowText.concat(text || []);
    }, [])
    .join("\n");

  await navigator.clipboard.writeText(tableCellContent);
}

/**
 * Helper function to determine whether a set of coords is "within" our copy square tuple.
 */
function isCellWithinSquare([i, j]: TableCellCoords, square: CopySquare) {
  return square[0][0] <= i && square[1][0] >= i && square[0][1] <= j && square[1][1] >= j;
}
