import { useEffect } from "react";

export default function usePageTitle(title: string) {
  useEffect(() => {
    document.title = `Cambio \u2022 ${title}`;

    return () => {
      document.title = "Cambio";
    };
  });
}
