import IconButton from "@/components/Button/IconButton";

import { classnames } from "@/js/utils/cambio";

interface PaginationProps {
  currentPage: number;
  onChangePage: (page: number) => void;
  itemsPerPage: number;
  total: number;
}

const NUM_OF_SHORTCUT_BUTTONS = 5;

/**
 * Generic pagination component. Always shows back and forward buttons, as well as the first and
 * last pages. It will then show up to five shortcut buttons in the middle, or if there are more
 * pages than that, it will show ellipses after the first button and before the last button.
 */
export default function Pagination({
  currentPage,
  onChangePage,
  itemsPerPage,
  total,
}: PaginationProps) {
  const totalPages = Math.ceil(total / itemsPerPage) - 1;
  const firstShortcutIndex = Math.min(
    Math.max(1, currentPage - 2),
    Math.max(1, totalPages - NUM_OF_SHORTCUT_BUTTONS),
  );
  // shortcut pages is a list of page numbers that will map to buttons that, when clicked,
  // go directly to that page (as opposed to using the carets to move page by page)
  const shortcutPages = [0]
    .concat(
      Array.from(
        { length: Math.min(NUM_OF_SHORTCUT_BUTTONS, totalPages - 1) },
        (el, i) => i + firstShortcutIndex,
      ),
    )
    .concat([totalPages]);

  return itemsPerPage < total ?
      <div className="Pagination">
        <IconButton
          disabled={!currentPage}
          icon="chevron-left"
          onClick={() => onChangePage(Math.max(0, currentPage - 1))}
        />
        {shortcutPages.map((index, i) =>
          // the second-to-last space on either end gets an ellipsis if it represents more than
          // one page
          (i === 1 && index > 1) || (i === shortcutPages.length - 2 && totalPages - index > 1) ?
            <span key={index} className="ellipsis">
              ...
            </span>
          : <button
              key={index}
              className={classnames({ selected: currentPage === index })}
              onClick={() => onChangePage(index)}
            >
              {index + 1}
            </button>,
        )}
        <IconButton
          disabled={currentPage >= totalPages}
          icon="chevron-right"
          onClick={() => onChangePage(Math.min(currentPage + 1, totalPages))}
        />
      </div>
    : null;
}
